var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { menuItemClasses } from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { getPosition } from './utils';
import { StyledArrow } from './styles';
// ----------------------------------------------------------------------
export default function CustomPopover(_a) {
    var _b;
    var open = _a.open, children = _a.children, _c = _a.arrow, arrow = _c === void 0 ? 'top-right' : _c, hiddenArrow = _a.hiddenArrow, sx = _a.sx, other = __rest(_a, ["open", "children", "arrow", "hiddenArrow", "sx"]);
    var _d = getPosition(arrow), style = _d.style, anchorOrigin = _d.anchorOrigin, transformOrigin = _d.transformOrigin;
    return (_jsxs(Popover, __assign({ open: Boolean(open), anchorEl: open, anchorOrigin: anchorOrigin, transformOrigin: transformOrigin, slotProps: {
            paper: {
                sx: __assign(__assign(__assign({ width: 'auto', overflow: 'inherit' }, style), (_b = {}, _b["& .".concat(menuItemClasses.root)] = {
                    '& svg': {
                        mr: 2,
                        flexShrink: 0,
                    },
                }, _b)), sx),
            },
        } }, other, { children: [!hiddenArrow && _jsx(StyledArrow, { arrow: arrow }), children] })));
}
